<template>
	<read-document module="SUPPLY" />
</template>
<script>
import ReadDocument from '@/components/agreement/readDocument/ReadDocument.vue';

export default {
	name: 'ReadDocumentView',
	components: { ReadDocument },
};
</script>
