import { mapActions } from 'vuex';
import { $EventBus } from '@/main';
import DecoupledDocument from '@ckeditor/ckeditor5-build-decoupled-document';

export default {
	name: 'ReadDocument',
	props: {
		module: {
			type: String,
			required: true,
		},
	},
	data: () => ({
		editor: DecoupledDocument,
		editorData: '',
		editorConfig: {
			language: 'es',
			toolbar: {
				items: [],
			},
		},
		loadingData: false,
		precontract: {
			id: '',
			name: '',
		},
	}),
	computed: {
		agreementWordId() {
			return this.$route.params?.documentId || null;
		},
		breadcrumbs() {
			const arr = [
				{
					module: 'LEGAL',
					moduleName: 'Legal',
					moduleRouteName: 'ContractHome',
					precontractRouteName: 'ContractReadAgreement',
				},
				{
					module: 'SUPPLY',
					moduleName: 'Abastecimiento',
					moduleRouteName: 'AgreementHome',
					precontractRouteName: 'ReadAgreement',
				},
				{
					module: 'SUPPLIER',
					moduleName: 'Proveedor',
					moduleRouteName: 'SupplierHome',
					precontractRouteName: 'SupplierPrecontractDetail',
				},
			];
			const found = arr.find((e) => e.module === this.module);
			let itemsBread = [
				{
					text: 'Inicio',
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: 'Dashboard',
					},
				},
				{
					text: found?.moduleName,
					link: true,
					exact: true,
					disabled: false,
					to: {
						name: found?.moduleRouteName,
					},
				},
			];
			if (this.precontract?.id) {
				itemsBread.push(
					{
						text: this.precontract?.name,
						link: true,
						exact: true,
						disabled: false,
						to: {
							name: found?.precontractRouteName,
							params: {
								agreementId: this.precontract?.id,
							},
						},
					},
					{
						text: 'Documento',
						disabled: true,
						href: '/',
					}
				);
			}
			return itemsBread;
		},
	},
	watch: {},
	created() {
		this.readAgreement();
	},
	methods: {
		...mapActions('agreement', ['readAgreementWord']),

		async readAgreement() {
			if (this.agreementWordId) {
				this.loadingData = true;
				const { ok, response, error } = await this.readAgreementWord({
					agreementWordId: this.agreementWordId,
					with_values_format: this.$route.name != 'ReadDocument',
				});
				if (ok) {
					this.precontract = {
						name: response?.data?.agreement?.name,
						id: response?.data?.agreement_id,
					};
					this.editorData = response?.data?.new_content_with_values;
					// if (this.module === 'SUPPLY') {
					// 	this.editorData = response?.data?.new_content;
					// } else {
					// 	this.editorData = response?.data?.new_content_with_values;
					// }
					// $EventBus.$emit('showSnack', 'success', response?.message[0]);
				} else {
					let msm = error?.data?.message;
					msm = Array.isArray(msm) ? msm.join(', ') : msm;
					this.showAlert('error', msm);
				}
				this.loadingData = false;
			}
		},

		showAlert(color = '', msm = '') {
			$EventBus.$emit('showSnack', color || 'success', msm);
		},

		onReady(editor) {
			// Insert the toolbar before the editable area.
			editor.enableReadOnlyMode(editor.id);
			editor.ui
				.getEditableElement()
				.parentElement.insertBefore(
					editor.ui.view.toolbar.element,
					editor.ui.getEditableElement()
				);
		},
	},
	beforeDestroy() {},
	components: {},
};
